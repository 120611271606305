import { CLIENT_FLICKR_API_KEY } from "$env";

const FLICKR_ARGS = {
  method: "flickr.groups.pools.getPhotos",
  api_key: CLIENT_FLICKR_API_KEY,
  group_id: "3750956@N22",
  format: "json",
  extras: "url_t,url_s,url_q,url_m,url_n,url_z,url_c,url_l,url_o",
  nojsoncallback: "true",
};

function select(photo: any) {
  return {
    href: `https://www.flickr.com/photos/${photo.ownername}/${photo.id}`,
    url_low: String(photo.url_t),
    url_high: String(photo.url_z),
  };
}

export async function getRandomPhoto() {
  const response = await fetch(
    "https://api.flickr.com/services/rest/?" + new URLSearchParams(FLICKR_ARGS)
  );
  const json = (await response.json()).photos.photo;
  return select(json[Math.floor(Math.random() * json.length)]);
}
