import { bodyEl, navEl, photoLinkEl } from "@/library/elements";
import { getRandomPhoto } from "@/library/flickr";

async function loadBackground() {
  const photo = await getRandomPhoto();
  bodyEl.style.backgroundImage = `var(--background-gradient), url(${photo.url_high}), url(${photo.url_low})`;
  photoLinkEl.setAttribute("href", photo.href);
  navEl.append(" | ");
  navEl.appendChild(photoLinkEl);
}

loadBackground();

if (!navigator.onLine) console.warn("Application offline.");
if ("serviceWorker" in navigator) navigator.serviceWorker.register("sw.js");

console.log(
  "Jurassic Park, System Security Interface\nVersion 4.0.5, Alpha E\nReady..."
);
